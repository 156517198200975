import React from "react"
import TipOfTheDayPosts from "../components/tipOfTheDay"
import nordBannerImg from "../images/tip-of-the-day/nord-banner.png"
import Logo from "../components/logo"


const TipOfTheDay = () => {
  return (
    <div className="container mt-5">
      <div className="tip-top-banner text-center py-3">
        <a class="pointer" href="https://go.nordvpn.net/aff_c?offer_id=30&amp;aff_id=30518&amp;url_id=323&amp;aff_sub=tip300_250" target="_blank" rel="noopener noreferrer">
          <img src={nordBannerImg} alt="nord" className="img-fluid"/>
        </a>
      </div>
      <div className="row align-items-start mt-3">
        <div className="col-md-8 pt-4 pr-5">
          <Logo color="black" tag="p" productName="Tip of the Day" className="text-center text-md-left mb-5" fsMax={21.5}/>
          <TipOfTheDayPosts />
        </div>
      </div>
    </div>
  )
}

export default TipOfTheDay

