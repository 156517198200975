import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Payout from "../components/pressroomLayout"
import PropTypes from "prop-types"
import { removeTrailingSlash } from "../utils/gatsby-node-helpers"

 const TipOfTheDay = ({ data }) => {
  return (
    <Payout>
      {
        data.allWordpressWpTip.edges.map(({ node }) => {
          let link = `/tip-of-the-day` + decodeURI(node.path)
          link = removeTrailingSlash(link)
          return (
            <div className="col-12 p-0 m-0" key={node.wordpress_id}>
              <Link id={node.path} className="text-decoration-none" to={link}>
               <h4 className="h5 text-body" dangerouslySetInnerHTML={{ __html: node.title }} />
              </Link>
              <div className="text-muted d-block mb-4">{node.date}</div>
            </div>
          )
        })
      }
    </Payout>
  )
}
export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressWpTip(sort: {fields: date, order: DESC}) {
          edges {
            node {
              title
              date(formatString: "MMMM DD, YYYY")
              wordpress_id
              path
            }
          }
        }
      }
    `}
    render={data => <TipOfTheDay data={data} {...props} />}
  />
)

TipOfTheDay.propTypes = {
    data: PropTypes.shape({
      allWordpressWpTip: PropTypes.shape({
        edges: PropTypes.shape({
          node: PropTypes.shape({
            title: PropTypes.string.isRequired,
            date: PropTypes.string.isRequired,
            wordpress_id: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}